.explore-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.explore-container > div {
  background-color: #f0f0f0f0;
  border-radius: 15px;
  padding: 20px;
  width: calc(45% - 10px);
  box-sizing: border-box;
}

.explore-container > div:first-child {
  margin-right: 50px;
}

.explore-container > div:last-child {
  margin-left: 10px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.see-more {
  background: none;
  border: none;
  color: #ec611c;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none; /* Remove the underline */
  font-weight: bold;
}

@media (max-width: 850px) {
  .explore-container > div {
    width: 80%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px; 
  }
}
.brand-slide {
  height: 200px; /* Set to your desired height */
}

.brand-slide img {
  object-fit: cover;
}

.limited-edition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  height: 20vh;
  max-height: 350px;
  overflow: auto;
  border-radius: 15px;
  background: #f0f0f0;
  margin: 20px;
  padding: 10px;

}
