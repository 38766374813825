@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bold-text {
  font-weight: 700; /* This applies the bold weight */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.header {
  background-color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add this line */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Adjust as needed */
}

.header .nav {
  display: flex;
  align-items: center;
}

.header .nav a {
  color: black;
  text-decoration: none;
  margin: 0 15px; /* Increased distance */
  font-weight: bolder;
  color: black;
}

.header .logo {
  flex-grow: 1;
  text-align: center;
}

.header .cart {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bolder;

}

.header .cart-icon {
  margin-left: 10px;
}

.sign-out-btn {
  background-color: rgb(209, 203, 203);
  color: black;
  border: none;
  padding: 11px 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  transition: background-color 0.5s, color 0.5s; /* smooth transition */
  border-radius: 7px; /* makes the button rounded */
  
}
.sign-out-btn:hover {
  background-color: black;
  color: white;
}

.header .right-section {
  display: flex;
  align-items: center;
}

.header .right-section .cart {
  margin-right: 20px;
}

.header .search {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: 0 20px; /* Add some horizontal padding */
}

.header .search input {
  width: 50%; /* Make it take up the full width of its container */
  padding: 10px; /* Add some padding to make it taller */
  font-size: 16px; /* Increase the font size */
  border-radius: 5px; /* Add some border radius to make it look more modern */
  border: 0px solid #ccc; /* Add a border */
  background-color: #f2f2f2; /* Make it a little gray inside */
}

.login-link {
  color: black;
  text-decoration: none;
  margin-right: 30px;
  font-weight: bold;



}
.join-us-link {
  background-color: white;
  border-color: black;
  color: black;
  border-radius: 10px;
  padding: 10px 15px;
  font-weight: bolder;
  border: 2px solid black;
  cursor: pointer;
  text-decoration: none;

}

.join-us-link:hover {
  text-decoration: none;
  background-color: black;
  color: white;
}

.cart-popout {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  width: 300px; /* Adjust as necessary */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
  color: black; /* or any color that contrasts with the background */

}
 
.cart-item {
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  font-size: 14px; /* Adjust as necessary */
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart-item-details {
  flex-grow: 1;
}


.cart-actions button {
  padding: 8px 12px;
  margin: 5px;
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.cart-actions button:hover {
  background-color: #45a049;
}

.cart-actions {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.remove-item-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: rgb(166, 165, 165);
  font-weight: bold;
  /* Adjust padding as needed */
  padding: 5px 10px;
}
.cart-badge {
  position: absolute;
  top: -5px; /* Adjust as needed */
  right: -5px; /* Adjust as needed */
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 10px; /* Smaller font size */
  width: 15px; /* Smaller width */
  height: 15px; /* Smaller height */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  
}

.cart {
  position: relative; /* Ensure this is relative for proper badge positioning */
  font-size: 23px; 
}

@media (max-width: 600px) {
  .header .logo {
    display: none;
  }
  .header .search {
    flex-grow: 2;
  }
  .header .search input {
    width: 75%; /* Increase the width */
    padding: 10px; /* Increase the padding */
    font-size: 15px; /* Increase the font size */
  }
}
