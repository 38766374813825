/* styles.css */
.join-us-button {
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    background: transparent;
    border: 2px solid white;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 800; /* Make the text thicker */
  }
  
  .join-us-button:hover {
    background: white;
    color: black;
  }

  /* GuestBanner.css */

.image {
  height: 70%;
  max-width: 100%; /* corrected from maxWidth */
  object-fit: cover; /* corrected from objectFit */
  border-radius: 0 15px 15px 0; /* corrected from borderRadius */
  opacity: 0.8;
  margin-bottom: -140px; /* corrected from marginBottom */
}

/* Hide the image on screens less than or equal to 768px wide */
@media (max-width: 768px) {
  .image {
    display: none;
  }
}