html, body, #root, .App, .main-container {
    height: 100%;
  }
  
  .main-container {
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .main-content {
    display: flex;
    flex-grow: 1; /* This will make main-content take up the remaining space */
  }
  
  
  .products-section {
    width: 100%; /* Adjust as needed */
    height: 100%;
    overflow: auto; /* Add a scrollbar if content overflows */
  }

  .filters-section {
    width: 100%;
   
  }
  
  .filters-section button {
    margin: 10px 0; /* Add some vertical spacing between buttons */
    background: none;
    border: none;
    cursor: pointer;
    color: gray; /* Make the text white */
    font-weight: bold; /* Make the text bold */
  }

  .products-title {
    color: #f599b1; /* Make the text pink */
    font-size: 2.5em; /* Make the text large */
    margin-top: -1px; /* Move the title up */
  }

  .products-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px; /* Add some space above the product cards */
  }

  .error-message {
    color: #f599b1; /* Pretty pink color */
    font-size: 1.5em; /* Larger font size */
    text-align: center; /* Center align the text */
    margin: 20px; /* Add some margin for spacing */
    /* You can add more styling as per your design requirements */
  }

  .content-section {
  display: flex;
}

.sidebar {
  flex: 1; /* Adjust this value to change the width of the sidebar */
  /* Add other styles for the sidebar */
}

.main-content {
  flex: 3; /* Adjust this value to change the width of the main content */
  /* Add other styles for the main content */
}
  