.products-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items to the start */
  align-items: stretch; /* Stretch items to fill the container height */
  gap: 10px; /* Add gap between items for better spacing */
}

.product-card {
  background-color: #ffffff;
  padding: 15px;
  padding-bottom: 5px; /* Increased bottom padding to create space */
  margin-bottom: 10px;
  width: calc((100% / 5) - 50px);
  height: 300px;
  transition: transform 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-card img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Add this to maintain aspect ratio */
}


.product-price {
  margin-top: 10px;
  font-size: 1em;
  font-weight: bold;
}

/* Initially hide the add-to-cart container */
.add-to-cart-container {
  position: absolute;
  bottom: -50px; /* Reduced height to bring it closer to the bottom of the image */
  left: 0;
  right: 0;
  transition: bottom 0.3s ease; /* Animate the bottom property */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Show the add-to-cart container when product card is hovered */
.product-card:hover .add-to-cart-container {
  bottom: 0; /* Move the container to the bottom of the card on hover */
}

.quantity-input {
  width: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.add-to-cart-button {
  background-color: #fa8e3e;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-to-cart-button:hover {
  background-color: #fabd8e;;
}

.out-of-stock-popup {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  background-color: gray; /* Changed from red to gray */
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  animation: fadeOut 1s ease-out 1s forwards; /* Fade out and hide after 1 second */

}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}


@media screen and (max-width: 1300px) {
  .product-card {
    width: calc((100% / 4) - 20px); /* Adjust the width for smaller screens */
    /* Add other styles for product card layout adjustments if necessary */
  }

  .add-to-cart-button {
    padding: 3px 6px; /* Reduce padding */
    font-size: 0.75em; /* Further reduce font size if needed */
    width: auto; /* Adjust width to fit content, or set a specific width */
    /* Add min-width or max-width if needed */
  }
}

@media screen and (max-width: 768px) {
  .product-card {
    width: calc((100% / 3) - 20px); /* Adjust the width for smaller screens */
    /* Add other styles for product card layout adjustments if necessary */
  }

  .add-to-cart-button {
    padding: 3px 6px; /* Reduce padding */
    font-size: 0.75em; /* Further reduce font size if needed */
    width: auto; /* Adjust width to fit content, or set a specific width */
    /* Add min-width or max-width if needed */
  }
}

@media screen and (max-width: 500px) {
  .product-card {
    width: calc((100% / 2) - 20px); /* Adjust the width for smaller screens */
    /* Add other styles for product card layout adjustments if necessary */
  }

  .add-to-cart-button {
    padding: 3px 6px; /* Reduce padding */
    font-size: 0.75em; /* Further reduce font size if needed */
    width: auto; /* Adjust width to fit content, or set a specific width */
    /* Add min-width or max-width if needed */
  }
}


.added-to-cart-popup {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 10px;
  width: 200px; /* Adjust as necessary */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
  text-align: center;
  border-radius: 5px;
}