@media screen and (min-width: 1024px) {
    /* Styles for screens larger than 1024px (full screen) */
    .login-container, .image-container {
        flex: 1; /* Split screen equally */
    }
}

@media screen and (max-width: 1023px) {
    /* Styles for screens smaller than 1024px */
    .login-container, .image-container {
        flex: 0 0 100%; /* Full width for each container */
    }
    .image-container {
        display: none; /* Hide the image container */
    }
}
