.button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: grey;
    color: white;
  }

  
  .container {
    display: flex;
    justify-content: center;
  }
  
  .sidebar {
    width: 200px; /* Adjust as necessary */
    flex-shrink: 0;
  }
  .main-content {
    max-width: 1200px; /* Adjust as necessary */
    width: 100%;
    margin: 0 auto;
    margin-right: 50px; /* Adjust as necessary */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  @media (max-width: 600px) {
    .sidebar {
      display: none;
    }
    .container {
      flex-direction: column;
    }
  }